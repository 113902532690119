import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { breakpoints, gridMaxWidth, gridPadding } from 'styles/theme';
import { mediaBetween, mobileOnly, mediaUp, mediaDown } from 'styles/utils';

const mobileOnlyCss = css`
  ${mobileOnly} {
    display: initial;
  }
`;

const tabletOnlyCss = css`
  ${mediaBetween(breakpoints.tabletPx, breakpoints.desktopPx)} {
    display: initial;
  }
`;

const desktopUpCss = css`
  ${mediaUp(breakpoints.desktopPx)} {
    display: initial;
  }
`;

const gridDownCss = css`
  ${mediaDown(`${gridMaxWidth + gridPadding - 1}px`)} {
    display: initial;
  }
`;

const gridUpCss = css`
  ${mediaUp(`${gridMaxWidth + gridPadding}px`)} {
    display: block;
  }
`;

const breakpointCss = ({ mobile, tablet, desktop, gridDown, gridUp }) => css`
  display: none;

  ${mobile && mobileOnlyCss}
  ${tablet && tabletOnlyCss}
  ${desktop && desktopUpCss}
  ${gridUp && gridUpCss}
  ${gridDown && gridDownCss}
`;

/**
 * A helper component to show it's children on specific screen sizes.
 * By default the component is hidden with `display: none`.
 *
 * This is useful since we can't conditionally render components based on screen
 * size, due to inconsistency with the DOM tree from SSR.
 *
 * @param {object} props
 */
function Breakpoint({
  children,
  className,
  component: Component = 'div',
  mobile,
  tablet,
  desktop,
  gridDown,
  gridUp,
  ...restProps
}) {
  return (
    <Component
      className={className}
      css={breakpointCss({ mobile, tablet, desktop, gridDown, gridUp })}
      {...restProps}
    >
      {children}
    </Component>
  );
}

Breakpoint.propTypes = {
  className: PropTypes.string,
  /** The content of the component */
  children: PropTypes.node,
  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a React component.
   */
  component: PropTypes.elementType,
  /** Show content on mobile only */
  mobile: PropTypes.bool,
  /** Show content on tablet only */
  tablet: PropTypes.bool,
  /** Show content on desktop only */
  desktop: PropTypes.bool,
  /** Show content on screen sizes below grid */
  gridDown: PropTypes.bool,
  /** Show content on screen sizes above grid */
  gridUp: PropTypes.bool,
};

export default Breakpoint;
